<!--
* @description:
* @fileName exhibitonList.vue
* @author hvv
* @date 2022/01/13 15:21:21
!-->
<template>
  <div>
    <vab-query-form>
      <vab-query-form-left-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          添加
        </el-button>
        <!-- <el-button :icon="Delete" type="danger" @click="handleDelete($event)">
          批量删除
        </el-button> -->
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item>
            <el-input
              v-model.trim="queryForm.type"
              clearable
              placeholder="请输入名称"
            />
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="类型名称" prop="type" show-overflow-tooltip />
      <el-table-column label="操作" show-overflow-tooltip width="285">
        <template #default="{ row }">
          <el-button type="primary" @click="handleEdit(row)">
            <vab-icon icon="edit-2-line" />
            编辑
          </el-button>
          <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="settings-6-line" />
            删除
          </el-button>
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination
      background
      :current-page="queryForm.pageNumber"
      :layout="layout"
      :page-size="queryForm.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
    <edit ref="editRef" @fetch-data="fetchData" />
  </div>
</template>

<script>
  import {
    defineComponent,
    defineAsyncComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'

  import { Delete, Plus, Search } from '@element-plus/icons'
  import { getEventType, pageStatus, editEventType } from '@/api/page'
  export default defineComponent({
    name: 'eventType',
    components: {
      Edit: defineAsyncComponent(() => import('./components/eventTypeEdit')),
    },
    setup() {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        queryForm: { type: '', pageNumber: 1, pageSize: 10 },
        total: 0,
        listLoading: false,
        list: [],
        layout: 'prev, pager, next',
        editRef: null,
      })

      const handleEdit = (row) => {
        if (row.id) {
          state.editRef.showEdit(row)
        } else {
          state.editRef.showEdit()
        }
      }

      const handleConfig = (row) => {
        proxy.$router.replace(`/page/pageConfigModule/${row.id}`)
      }

      const handleDelete = (row) => {
        if (row.id) {
          proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
            row.delFlag = true
            let data = {
              delFlag: true,
              id: row.id,
            }
            const { msg } = await editEventType(data)
            proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
            await fetchData()
          })
        }
      }

      const queryData = () => {
        fetchData()
      }

      const fetchData = async () => {
        state.listLoading = true
        const { data } = await getEventType(state.queryForm)
        state.list = data.data
        state.total = data.total
        state.listLoading = false
      }

      const handleSizeChange = (val) => {
        state.queryForm.pageSize = val
        fetchData()
      }
      const handleCurrentChange = (val) => {
        state.queryForm.pageNumber = val
        fetchData()
      }

      const shopStatusChange = async (id, status) => {
        let statusKey = status ? 'ACTIVATION' : 'OFFLINE'
        try {
          await pageStatus(id, statusKey)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
        } catch (e) {
          console.log(e)
        }

        fetchData()
      }
      fetchData()

      return {
        ...toRefs(state),
        handleEdit,
        queryData,
        fetchData,
        handleSizeChange,
        handleCurrentChange,
        handleDelete,
        shopStatusChange,
        handleConfig,
        Delete,
        Search,
        Plus,
      }
    },
  })
</script>
<style lang="scss" scoped></style>
